import React from 'react'
import { Link } from 'gatsby'
import profImg from '../../images/profImg.png'
import bspImg from '../../images/prof-bsp.png'
import mslantGlass from '../../images/mag-slant.png'
import sectionBg from '../../images/professional_sectionbg.png'

function ProfessionalContent() {
	return (
		<div id="professional-content">
			<div className="section-p">
				<img src={sectionBg} alt="" className="sectionBg"></img>

				<div className="ui container first-section">
					<div className="ui stackable equal width grid">
						<div className="column">
							<h3 className="text-highlight">
								Reinventing <strong>legal research</strong> <br />
								and delivery of knowledge <br /> on any device
							</h3>
							<div className="ui fitted divider"></div>
							<br />

							<span>
								Access relevant legal knowledge and <br /> understand legal
								concepts easily.
							</span>
						</div>
						<div className="column">
							<img src={profImg} alt="" className="profImg"></img>
						</div>
					</div>
				</div>
			</div>
			<div className="section-p-1">
				<div className="ui stackable grid">
					<div className="seven wide column">
						<img src={bspImg} alt="" className="prof-bsp-img"></img>
					</div>
					<div className="nine wide column">
						<p>
							<h3 className="text-highlight text-description">
								<b>Empower</b> Your <br />
								Legal Study
								<br /> and Practice!
							</h3>
							<br></br>
							<Link to="/pricing">
								<span className="text-subscribe text-highlight">
									SUBSCRIBE NOW ▼
								</span>
							</Link>
						</p>
					</div>
				</div>
				<img src={mslantGlass} alt="" className="prof-mslantGlass" />
			</div>
		</div>
	)
}

export default ProfessionalContent
